











































































































































































































































































































































.searchbox {
    .searchboxItem {
        .el-input {
            input {
                width: 100%;
            }
        }
    }
}

.el-message-box {
    .el-message-box__btns {
        .el-button--primary {
            background-color: #5C6BE8;
            border: none;

            &:hover {
                background-color: #3d4fee;
            }
        }
    }
}

.el-table .el-table__fixed-right::before,
.el-table__fixed::before {
    height: 0 !important;
}
